<template>
  <b-card no-body class="p-2">
    <i18n path="home.manager.events-panel.sale-event">
      <template #dateTime>{{ $d(new Date(event.dateTime), "long") }}</template>
      <template #user>{{ event.user }}</template>
      <template #sold>{{ event.sold }}</template>
      <template #goods>{{ event.goods[$i18n.locale] }}</template>
    </i18n>
  </b-card>
</template>

<script>
export default {
  props: {
    event: { type: Object, required: true }
  }
};
</script>
