<template>
  <b-card class="w-100">
    <template #header>
      <h4>{{ $t("home.manager.events-panel.title") }}</h4>
    </template>
    <template #default>
      <edit-incident-modal></edit-incident-modal>
      <div v-for="(event, i) in events" :key="i">
        <vehicle-load-event v-if="event.type === 'vehicle-load-event'" :event="event"></vehicle-load-event>
        <incident-event v-else-if="event.type === 'incident-event'" :event="event"></incident-event>
        <sale-event v-else-if="event.type === 'sale-event'" :event="event"></sale-event>
      </div>
    </template>
  </b-card>
</template>

<script>
import VehicleLoadEvent from "./events/VehicleLoadEvent.vue";
import IncidentEvent from "./events/IncidentEvent.vue";
import SaleEvent from "./events/SaleEvent.vue";
import EditIncidentModal from "@/components/incident/EditIncidentModal.vue";

export default {
  components: {
    VehicleLoadEvent,
    IncidentEvent,
    SaleEvent,
    EditIncidentModal
  },
  data() {
    return {
      loadEventsTimeoutId: null,
      isDestroyed: false
    };
  },
  computed: {
    events() {
      return this.$store.state.events.events;
    }
  },
  methods: {
    loadEvents() {
      this.$store.dispatch("events/load").finally(() => {
        if (!this.isDestroyed) {
          this.loadEventsTimeoutId = setTimeout(() => this.loadEvents(), 2000);
        }
      });
    }
  },
  mounted() {
    this.loadEvents();
  },
  beforeDestroy() {
    clearTimeout(this.loadEventsTimeoutId);

    this.isDestroyed = true;
  }
};
</script>
