<template>
  <b-card no-body class="p-2">
    <i18n path="home.manager.events-panel.incident-event">
      <template #dateTime>{{ $d(new Date(event.dateTime), "long") }}</template>
      <template #user>{{ event.user }}</template>
      <template #incidentType>{{ $t(`incident-type.${event.incidentType}`) }}</template>
      <template #link>
        <b-link @click="onOpenIncident">
          {{ event.incidentNumber }}
        </b-link>
      </template>
    </i18n>
  </b-card>
</template>

<script>
import { CLOSED } from "@/const/incident-statuses.js";
import { OPEN_EDIT_INCIDENT_MODAL } from "@/const/events.js";

export default {
  props: {
    event: { type: Object, required: true }
  },
  methods: {
    onOpenIncident() {
      this.$store.dispatch("incidents/get", this.event.incidentId).then(resp => {
        if (resp.data.status !== CLOSED) {
          this.$bus.emit(OPEN_EDIT_INCIDENT_MODAL, resp.data);
        } else {
          this.$router.push({ name: "incident", params: { incidentId: this.event.incidentId } });
        }
      });
    }
  }
};
</script>
