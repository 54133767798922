<template>
  <b-container>
    <b-row class="mb-2">
      <b-col>
        <h2>{{ $t("home.manager-title", { userName: userName }) }}</h2>
      </b-col>
    </b-row>
    <b-row>
      <manager-dashboard></manager-dashboard>
    </b-row>
  </b-container>
</template>

<script>
import ManagerDashboard from "@/components/user/manager/ManagerDashboard.vue";

import auth from "@/services/auth.js";

export default {
  components: {
    ManagerDashboard
  },
  computed: {
    userName: () => auth.userName()
  }
};
</script>
