<template>
  <b-card no-body class="p-2">
    <i18n path="home.manager.events-panel.vehicle-load-event">
      <template #dateTime>{{ $d(new Date(event.dateTime), "long") }}</template>
      <template #user>{{ event.user }}</template>
      <template #loadType>{{ $t(`vehicle-event-history.event.${event.loadType}`) }}</template>
      <template #link>
        <router-link :to="{ name: 'current-load', params: { vehicleId: event.vehicleId } }">
          {{ event.vehicleNumber }}
        </router-link>
      </template>
    </i18n>
  </b-card>
</template>

<script>
export default {
  props: {
    event: { type: Object, required: true }
  }
};
</script>
